import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { FilterObject } from 'state-domains/domain/drillhole';
import { ExportTemplate } from 'state-domains/domain/subscription/types';
import { buildExportTemplateUrlWithFilters } from 'state-domains/network';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { sendRetrieveRequest } from '../../../utils';

export const loadExportTemplates = (
    filters: FilterObject[],
): Observable<{ ExportTemplate: ExportTemplate[] }> => {
    const obs = sendRetrieveRequest(buildExportTemplateUrlWithFilters(filters));
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<{ ExportTemplate: ExportTemplate[] }>) => {
            return observableOf(convertToCamel<{ ExportTemplate: ExportTemplate[] }>(response));
        }),
    );
};
